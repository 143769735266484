import Pristine from "pristinejs";

export function initPristine(form) {
    return new Pristine(
        form,
        {
            classTo: 'form-input',
            errorClass: 'line-input--error',
            errorTextParent: 'form-input__error',
            errorTextTag: 'div',
            errorTextClass: 'error'
        }
    );
}