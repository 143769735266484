import Pristine from "pristinejs";
import {useActionModal} from "@/shared/ui/action-modal";
import {initPristine} from "@/widgets/refund/initPristine";

const showError = () => useActionModal({
    heading: 'Ошибка',
    text: "Не удалось отправить заявку, пожалуйста, повторите позже или свяжитесь по телефону."
});

const showSuccess = () => useActionModal({
    heading: 'Ваш запрос на возврат успешно отправлен!',
    text: "Мы свяжемся с Вами в ближайшее время по электронной почте."
});
export function submitRefundForm() {

    const refundForm = document.getElementById("refund-form");
    const submitBtn = refundForm.querySelector('.action-btn');
    const tariffInput = document.getElementById('refund_tariff');

    const pristine = initPristine(refundForm);

    refundForm.addEventListener('submit', async function (e) {
        e.preventDefault();
        if (pristine.validate()) {
            submitBtn.disabled = true;
            const formData = new FormData(refundForm);

            const res = await fetch('/mail.php', {
                method: 'POST',
                body: formData
            });

            console.log(res);

            if (res.ok) {
                refundForm.reset();
                tariffInput.value = '';
                showSuccess();
                const wrapper = document.getElementById('refund-wrapper');
                wrapper.classList.remove('modal-window--active');
                refundForm.classList.remove('modal-window__target--active');
                document.body.classList.remove('modal-lock');
            } else {
                showError();
            }

            submitBtn.disabled = false;
        }
    });
}