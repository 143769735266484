import {submitRefundForm} from "@/widgets/refund/submitRefundForm";
import {createPhoneInputMask} from "@/shared/ui/phone-input-mask";
import Inputmask from "inputmask";

const initCustomSelects = (wrapper) => {
    const customSelects = document.querySelectorAll('.custom-select');

    const updateSelectedOptions = (customSelect) => {
        const selectedOptions = Array.from(customSelect.querySelectorAll('.option.active'))
            .map((option) => {
                return {
                    value: option.getAttribute('data-value'),
                    text: option.textContent.trim()
                };
            });

        const selectedValues = selectedOptions.map((option) => {
            return option.value;
        })

        customSelect.querySelector('.tags_input').value = selectedValues.join(', ');

        let tagsHTML = '';

        if(selectedOptions.length === 0) {
            tagsHTML = '<span>Выберите тариф</span>';
        } else {
            const maxTagsToShow = 2;
            let additionalTagsCount = 0;

            selectedOptions.forEach((option, index) => {
                if (index < maxTagsToShow) {
                    tagsHTML += `<span class="tag">
                                    <span class="tag__text">${option.text}</span>
                                    <span class="remove-tag" data-value="${option.value}">
                                        &times;
                                    </span>
                                </span>`
                } else {
                    additionalTagsCount++;
                }
            })

            if (additionalTagsCount > 0) {
                tagsHTML += `<span class="tag">+${additionalTagsCount}</span>`
            }
        }
        customSelect.querySelector('.selected-options')
            .innerHTML = tagsHTML;
    }

    customSelects.forEach((customSelect) => {
        const options = customSelect.querySelectorAll('.option');
        options.forEach((option) => {
            option.addEventListener('click', () => {
                option.classList.toggle('active');
                updateSelectedOptions(customSelect);
            })
        })
    })

    wrapper.addEventListener('click', (event) => {
        const removeTag = event.target.closest('.remove-tag');
        if (removeTag) {
            const customSelect = removeTag.closest('.custom-select');
            const valueToRemove = removeTag.getAttribute('data-value');
            const optionToRemove = customSelect.querySelector(`.option[data-value='${valueToRemove}']`);
            optionToRemove.classList.remove('active');
            updateSelectedOptions(customSelect);
        }
    })

    const selectBoxes = document.querySelectorAll('.select-box');
    selectBoxes.forEach((selectBox) => {
        selectBox.addEventListener('click', (event) => {
            if (!event.target.closest('.tag')) {
                event.preventDefault();
                selectBox.parentNode.classList.toggle('open');
            }
        })
    })

    wrapper.addEventListener('click', function(event) {
        if(!event.target.closest('.custom-select')
            && !event.target.classList.contains('remove-tag')) {
            customSelects.forEach((customSelect) => {
                customSelect.classList.remove('open');
            });
        }
    })

    updateSelectedOptions(customSelects[0]);
}

const initPassportSelect = () => {
    const select = document.getElementById("passport_select");
    const idFields = document.getElementById("id_fields");
    const htmlIdRf =
        `<div id="passport_rf" class="passport-select passport-select--active">
                    <label class="form-input">
                    <span class="form-input__wrapper">
                        <span class="form-input__title">Серия/Номер документа</span>
                        <span class="form-input__control form-input__control--outlined cursor-default form-input__control--sm">
                            <input class="form-input__src form-input__src--phone-number pr-[15px]"
                                   name="id_number" type="text" required
                                   data-pristine-required-message="Введите номер документа"
                                   placeholder="XXXX XXXXXX"
                                   data-inputmask="'mask': '9999 999999'" inputmode="numeric"
                                   id="id_number_rf"
                                   minlength="11"
                            />
                        </span>
                    </span>
                        <span class="form-input__error"></span>
                    </label>
                    <label class="form-input">
                    <span class="form-input__wrapper">
                        <span class="form-input__title">Дата выдачи</span>
                        <span class="form-input__control form-input__control--outlined cursor-default form-input__control--sm">
                            <input class="form-input__src form-input__src--phone-number pr-[15px]"
                                   name="id_date" type="text" required
                                   data-pristine-required-message="Введите дату выдачи"
                                   placeholder="26.05.1988"
                                   id="id_date_input"
                                   minlength="10"
                            />
                        </span>
                    </span>
                        <span class="form-input__error"></span>
                    </label>
                    <label class="form-input">
                    <span class="form-input__wrapper">
                        <span class="form-input__title">Кем выдан</span>
                        <span class="form-input__control form-input__control--outlined cursor-default">
                            <input class="form-input__src form-input__src--phone-number pr-[15px]"
                                   name="id_issued" type="text" required
                                   data-pristine-required-message="Введите поле"
                                   placeholder=""
                            />
                        </span>
                    </span>
                    <span class="form-input__error"></span>
                </label>
        </div>`;

    const htmlIdZagran =
        `<div id="passport_zagran" class="passport-select passport-select--active">
        <label class="form-input">
                    <span class="form-input__wrapper">
                        <span class="form-input__title">Номер документа</span>
                        <span
                            class="form-input__control form-input__control--outlined cursor-default form-input__control--sm">
                            <input class="form-input__src form-input__src--phone-number pr-[15px]"
                                   name="id_number" type="text" required
                                   data-pristine-required-message="Введите номер документа"
                                   placeholder="XX XXXXXXX"
                                   data-inputmask="'mask': '99 9999999'" inputmode="numeric"
                                   id="id_number_zagran"
                                   minlength="10"
                            />
                        </span>
                    </span>
            <span class="form-input__error"></span>
        </label>
        <label class="form-input">
                    <span class="form-input__wrapper">
                        <span class="form-input__title">Срок действия</span>
                        <span
                            class="form-input__control form-input__control--outlined cursor-default form-input__control--sm">
                            <input class="form-input__src form-input__src--phone-number pr-[15px]"
                                   name="id_date" type="text" required
                                   data-pristine-required-message="Введите дату окончания срока действия"
                                   placeholder="26.05.1988"
                                   id="id_date_input"
                                   minlength="10"
                            />
                        </span>
                    </span>
            <span class="form-input__error"></span>
        </label>
        </div>`;

    idFields.innerHTML = htmlIdRf;
    Inputmask("datetime", {inputFormat: "dd.mm.yyyy", placeholder: "дд.мм.гггг", clearIncomplete: true, greedy: false}).mask(document.getElementById('id_date_input'));
    Inputmask({clearIncomplete: true, greedy: false}).mask(document.getElementById('id_number_rf'));

    select.addEventListener("change", function(){
        const chosenValue = this.value;
        if (chosenValue === 'Загранпаспорт РФ') {
            idFields.innerHTML = htmlIdZagran;
            Inputmask({clearIncomplete: true, greedy: false}).mask(document.getElementById('id_number_zagran'));
        } else {
            idFields.innerHTML = htmlIdRf;
            Inputmask({clearIncomplete: true, greedy: false}).mask(document.getElementById('id_number_rf'));
        }
        Inputmask("datetime", {inputFormat: "dd.mm.yyyy", placeholder: "дд.мм.гггг", clearIncomplete: true, greedy: false}).mask(document.getElementById('id_date_input'));
        submitRefundForm();
    });
}

export const initRefundModal = () => {
    const refundButton = document.getElementById('refund-btn');

    const wrapper = document.getElementById('refund-wrapper');
    const overlay = document.getElementById('refund-overlay');
    const refundForm = document.getElementById('refund-form');
    const closeButton = document.getElementById('refund-close-button');


    refundButton.addEventListener('click', () => {
        wrapper.classList.add('modal-window--active');
        refundForm.classList.add('modal-window__target--active');
        document.body.classList.add('modal-lock');
    })

    wrapper.addEventListener('click', (event) => {
        if (event.target === wrapper || event.target === overlay || event.target === closeButton) {
            wrapper.classList.remove('modal-window--active');
            refundForm.classList.remove('modal-window__target--active');
            document.body.classList.remove('modal-lock');
        }
    })

    createPhoneInputMask(document.getElementById('refund_phone'));
    Inputmask("datetime", {inputFormat: "dd.mm.yyyy", placeholder: "дд.мм.гггг", clearIncomplete: true, greedy: false}).mask(document.getElementById('date_input'));
    Inputmask({clearIncomplete: true, greedy: false}).mask(document.getElementById('card_number'));

    initCustomSelects(wrapper);
    initPassportSelect();
    submitRefundForm();
}